.Notification {
    position: absolute;
    left: 1rem;
    bottom: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.NotificationBody {
    font-family: var(--Fonte) !important;
    font-weight: 400;
    font-size: 13px;
    color: black;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    min-width: 300px;
    justify-content: space-between; 
    padding: 0.5rem;
    background-color: var(--NotificationInfo);
    border-radius: 5px;   
}

.NotificationBody span {
    color: #292b2c;
    font-family: var(--Fonte) !important;
}

.NotificationBody svg {
    color: #292b2c;
    cursor: pointer;
}


.NotificationHidden {
    height: 0px;
    -webkit-transition: height 0.5s linear;
    -moz-transition: height 0.5s linear;
    -ms-transition: height 0.5s linear;
    -o-transition: height 0.5s linear;
    transition: height 0.5s linear;
}

.NotificationHidden.NotificationOpen {
    height: 80px;
    -webkit-transition: height 0.5s linear;
    -moz-transition: height 0.5s linear;
    -ms-transition: height 0.5s linear;
    -o-transition: height 0.5s linear;
    transition: height 0.5s linear;
}


.NotificationErro {
    background-color: var(--NotificationErro);
}
.NotificationInfo {
    background-color: var(--NotificationInfo);
}
.NotificationEvent {
    background-color: var(--NotificationEvent); 
}