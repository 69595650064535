:root {
    --AzulTexto: #0275d8;
    --VermelhoTexto: #df4759;
    --TextoBotao: #fff;
    --TextoInformacao: darkgray;
    --TextoEscuro: rgb(54, 54, 54);

    --FundoAzul: #0275d8;
    --FundoEscuro: #292b2c;
    --VerdeBotao: #4CAF50;
    --InputBorderColor: #ccc;
    --BackGroundColorTelas: whitesmoke;
    --FundoCenariosHeader: #f0ad4e;
    --CirculoTitulos: #f0ad4e;
    --EmAndamento: #F9CA47;
    --Aberto: #42AADA;
    --Concluido: #55c639;
    --Cancelado: #df4759;
    --FundoBody: ;
    --Fonte: 'Kanit', sans-serif;

    --TextoCabecalhoTabela: white;
    --LinhasTabela: #d0e1f3;


    /*SIDEBAR*/
    --SidebarBackground: #0275d8;
    --SideBarActiveLinkColor: #00000030;
    --SideBarActiveLinkColorHover: #00000030;
    --SidebarLinkTextColor: #FFF;
    --SidebarBoxShadow: 6px 4px 33px -17px rgba(0, 0, 0, 1);
    /*SIDEBAR*/

    /*SIDEBAR TRANSPARENTE
   --SidebarBackground: var(--BackGroundColorTelas);
    --SideBarActiveLinkColor: #0275d8;
    --SidebarLinkTextColor: #292b2c;
    --SideBarActiveLinkColorHover: #00000030;
    --SidebarBoxShadow: none;
   SIDEBAR TRANSPARENTE*/



    /*TELA DE PERFIL*/
    --InputBackgroundColor: white;
    /*TELA DE PERFIL*/



    /* NOTIFICAÇÂO */ 
    --NotificationErro: #d9534f;
    --NotificationInfo: #5bc0de;
    --NotificationEvent: #f0ad4e;

    /* NOTIFICAÇÂO */








    --FundoTransparente: transparent;
}



body {
    padding: 0px;
    margin: 0px;
    font-family: var(--Fonte);
    -ms-overflow-style: none;
    /* for Internet Explorer, Edge */
    scrollbar-width: none;
    /* for Firefox */
    overflow-y: scroll;
    background-color: whitesmoke;
}

body::-webkit-scrollbar {
    display: none;
    /* for Chrome, Safari, and Opera */
}


.App {
    height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: stretch;
}


* {
    font-family: var(--Fonte);
}


.LoadingContainer {
    width: 100%;
    height: 100dvh;  
    display: flex;
    justify-content: center;
    align-items: center;
  }