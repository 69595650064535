.CriarNotaForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 2rem;
}

.AddNote {
  min-width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  -webkit-box-shadow: 0px 0px 24px -7px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 24px -7px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 24px -7px rgba(0, 0, 0, 0.75);
  border-radius: 7px;
  padding: 0.5rem;
  background-color: white;
  padding-bottom: 1rem;
}


.AddNoteForm {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border-radius: 7px;
  padding: 0.5rem;
  background-color: white;
  padding-bottom: 1rem;
}

.AddNoteForm input {
  width: 100%;
  padding: 7px 20px;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: var(--Fonte);
  font-size: 12px;
  outline: none;
  color: rgba(0, 0, 0, .87);
}

.AddNoteForm textarea {
  width: 100%;
  padding: 7px 20px;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: var(--Fonte);
  font-size: 12px;
  outline: none;
  resize: none;
  overflow: hidden;
  color: rgba(0, 0, 0, .87);
}

.AddNoteForm textarea::placeholder {
  color: rgba(0, 0, 0, .87);
  opacity: 1;
  /* Firefox */
}

.AddNoteForm input::placeholder {
  color: rgba(0, 0, 0, .87);
  opacity: 1;
  /* Firefox */
}



.addNoteInput {
  padding: 7px 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: var(--Fonte);
  font-size: 12px;
  max-height: 100px;
  width: 70%;
  box-shadow: 10px 10px 5px -7px rgba(0, 0, 0, 0.75);
  outline: none;
}


.AddNoteFormFooter {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.circle-picker {
  border-radius: 6px;
  padding: 0.6rem;
  margin-top: -1rem;
  background-color: white;
  padding-bottom: 0 !important;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}

.AddNoteFormFooter button {
  border: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-right: 5px;
  margin-left: 5px;
  border-radius: 4px;
  color: rgba(0, 0, 0, .87);
  padding: 10px;
  font-size: 12px;
}

.AddNoteFormFooter button:hover {
  background-color: RGBA(0, 0, 0, 0.19);
}



@media (max-width: 800px) {
  .AddNote {
    min-width: 100%;
  }
}