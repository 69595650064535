.Nota {
  display: grid;
  grid-template-rows: 20% 75%; 
  padding: 0.5rem;
  margin-bottom: 0.5rem; 
  width: 100%;
  min-width: 150px;
  min-height: 100px;
  max-height: 300px;
  border-radius: 5px; 
  gap: 5%; 
}

.Nota:hover .NoteFooter button {
  color: rgba(39, 38, 38, 0.87) !important;
}

.Nota:hover {
  filter: brightness(100%);
  transform: scale(1.02);
  z-index: 50;
  box-shadow: 0px 0px 3px -1px rgb(135, 133, 133);
  cursor: pointer;
}



.NoteFooter button {
  border: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  color: rgba(39, 38, 38, 0.87);
  padding: 10px;
  font-size: 12px;
}

.NoteFooter button:hover {
  background-color: RGBA(0, 0, 0, 0.19);
}






.modal-content>div {
  border: 0 !important;
}



.CoresMenuDropdown .dropdown-item {
  border: none !important;
  background-color: transparent !important;
  margin: 0 !important;
  padding: 0 !important;
}


.dropdown-toggle::after {
  border-radius: 50% !important;
  border: none !important;
  outline: none;
}

.dropdown-toggle::after {
  border-radius: 50% !important;
  border: none !important;
  outline: none;
}

.dropdown-toggle::after svg {
  border: none !important;
  background-color: transparent !important;
  color: rgba(39, 38, 38, 0.87);
}

.PaletaCores {
  color: rgba(39, 38, 38, 0.87) !important;
}

.dropdown-toggle:hover {
  border: none !important;
  outline: none;
}

.dropdown-toggle:active svg {
  border: none !important;
  background-color: transparent !important;
  color: rgba(39, 38, 38, 0.87);
}

.dropdown-toggle:hover svg {
  border: none !important;
  background-color: transparent !important;
  color: rgba(39, 38, 38, 0.87);
  background-color: RGBA(0, 0, 0, 0.19);
}

.dropdown-toggle:hover button {
  border: none !important;
  background-color: transparent !important;
  color: rgba(39, 38, 38, 0.87);
  background-color: RGBA(0, 0, 0, 0.19);

}

.dropdown-toggle {
  border: none !important;
  background-color: transparent !important;
  color: rgba(39, 38, 38, 0.87);
}


.dropdown:hover {
  border: none !important;
  background-color: transparent !important;
  color: rgba(39, 38, 38, 0.87);

}

.ModalNoteTitulo {
  width: 100%;
}

.ModalNoteTitulo input {
  word-break: break-all;
  width: 100%;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: var(--Fonte);
  font-size: 12px;
  outline: none;
  resize: none;
  overflow: hidden;
  color: rgba(0, 0, 0, .87);
  background-color: transparent;

}

.ModalNoteBodyContent {
  height: 100%;
  max-height: none !important;
}

.modal-body {
  max-height: none !important;
  height: 25rem;
}

.ModalNoteBodyContent textarea {
  flex-grow: 1;
  width: 100%;
  max-width: 100%;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: var(--Fonte);
  font-size: 11px;
  outline: none;
  resize: none;
  color: rgba(0, 0, 0, .87);
  background-color: transparent;
  min-height: 100%;
  max-height: 25rem;
  word-wrap: break-word;
  word-break: break-all;
}

.ModalNoteBodyContent textarea::placeholder {
  color: rgba(0, 0, 0, .87);
}

.ModalNoteBodyContent textarea::-webkit-scrollbar {
  width: 5px;
}

.ModalNoteBodyContent textarea::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.ModalNoteBodyContent textarea::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}


.NoteHeader { 
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis; 
  max-width: 100% !important;
}


.NoteHeader span {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100% !important;
  background-color: aqua;
}

.NoteHeader input {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  text-overflow: ellipsis;
  max-width: 100% !important;
  font-size: .8rem;
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0 !important;
  width: 100%; 
  outline: none;
  cursor: pointer;   
  
}


.NoteBody {
  overflow: hidden;
  
}

.NoteBody pre {
  max-height: 100%;
  text-overflow: ellipsis;
  overflow-y: hidden;
  font-size: 10px;
  white-space: pre-wrap;
  text-overflow:ellipsis;
}



.NoteBody pre {
  font-size: 9px;
  width: 100%;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: var(--Fonte);
  outline: none;
  overflow: hidden;
  color: rgba(0, 0, 0, .87);
  background-color: transparent;
  height: fit-content !important;
  max-height: 100%;
  cursor: pointer;
  word-break: break-all;
  max-height: 100%;
}

@media (max-width: 800px) {
  .Nota {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    text-overflow: ellipsis;
    width: 100%;
    min-width: 100px;
    max-height: 300px;
    border-radius: 5px;
  }
}