.Notas {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: scroll;
  padding: 2rem;
}

.Notas h1 {
  font-family: var(--Fonte);
}


.DeleteHeader{
  padding-left: 2.1rem;
  font-size: 1.3rem;
  width: 100%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.Notas::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.Notas {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.Notas textarea {
  display: inline-block;
  
}


.my-masonry-grid {
  display: -webkit-box;
  /* Not needed if autoprefixing */
  display: -ms-flexbox;
  /* Not needed if autoprefixing */
  display: flex;
  width: 90%;
  margin-top: 2rem;
}

.my-masonry-grid_column {
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  /* gutter size */
  background-clip: padding-box;
}


.Photo {
  width: 100%;
  border-radius: 5px;
  position: relative;
  transition: all 0.3s ease;
  filter: brightness(80%);
}

.Photo:hover {
  filter: brightness(100%);
  transform: scale(1.02);
  z-index: 50;
  box-shadow: 0px 0px 5px 2px rgb(48, 47, 47);
}

.center-masonry {
  background: rgb(233, 232, 232);
}




@media (max-width: 800px) {
  .my-masonry-grid {
    display: -webkit-box;
    /* Not needed if autoprefixing */
    display: -ms-flexbox;
    /* Not needed if autoprefixing */
    display: flex;
    width: 100%;
    margin-top: 2rem;
  }

  .Notas {
    padding: 0.6rem;
  }
}