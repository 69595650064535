.NotFound {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding-top: 3rem;
}

.Page404Mobile {
  display: none;
} 

.NotFound img {
  width: 75%;

}


@media (max-width: 800px) {

  .Page404Mobile {
    display: block;
  }

  .NotFound img {
    width: 80%;
    
  }

  .Page404 {
    display: none;
  }
}